import { Row, Col, Card, Progress } from 'antd';
import 'antd/dist/antd.css';
import './App.css';

function App() {
  let data = [
    { value: "1.你的性别是？", options: [{ content: "汉子", tot: 105 }, { content: "妹子", tot: 100 }] },
    { value: "2.你的年级是？", options: [{ content: "大一刚入学呢", tot: 31 }, { content: "大二终于有学弟啦", tot: 34 }, { content: "大三老油条了~", tot: 105 }, { content: "大四都快毕业了", tot: 35 }] },
    { value: "3.请问你平均每天玩多长时间游戏呢？", options: [{ content: "1小时以内", tot: 15 }, { content: "1~3h", tot: 94 }, { content: "3~5h", tot: 76 }, { content: "5h以上", tot: 20 }] },
    { value: "4.你氪金多吗？", options: [{ content: "零氪玩家", tot: 19 }, { content: "微氪微氪（每月200以下）", tot: 85 }, { content: "中氪玩家（每月200~800）", tot: 73 }, { content: "重氪玩家（每月800~2000）", tot: 25 }, { content: "吾乃氪佬（每月2000以上）", tot: 3 }] },
    { value: "5.有没有给电竞主播打赏过礼物呢？", options: [{ content: "打赏过", tot: 61 }, { content: "没打赏过", tot: 144 }] },
    { value: "6.（限制打赏过礼物回答）打赏金额是多少呢？", options: [{ content: "100以内", tot: 48 }, { content: "100~500", tot: 9 }, { content: "500以上", tot: 4 }] },
    { value: "7.玩游戏是爱好还是打发时间？", options: [{ content: "爱好", tot: 101 }, { content: "打发时间", tot: 104 }] },
    { value: "8.你喜欢玩什么类型的游戏？（多选）", options: [{ content: "即时战略", tot: 178 }, { content: "卡牌", tot: 59 }, { content: "休闲", tot: 63 }, { content: "射击", tot: 157 }, { content: "其他", tot: 57 }] },
    { value: "9.你多久玩一次游戏呢？", options: [{ content: "空下来就玩", tot: 41 }, { content: "每天", tot: 102 }, { content: "两三天或每周一次", tot: 41 }, { content: "假期才玩", tot: 10 }, { content: "不玩游戏", tot: 11 }] },
    { value: "10.你喜欢看电竞直播吗？", options: [{ content: "喜欢", tot: 142 }, { content: "不喜欢", tot: 63 }] },
    { value: "11.你认为，游戏是否占用了你其他的社交时间呢？", options: [{ content: "占用了", tot: 53 }, { content: "没占用…吧？", tot: 152 }] },
    { value: "12.你是否觉得学习时间不够用呢？", options: [{ content: "确实", tot: 81 }, { content: "没有欸", tot: 124 }] },
    { value: "13.你是否通过游戏认识了新的朋友呢？", options: [{ content: "认识过", tot: 183 }, { content: "并没有", tot: 22 }] },
    { value: "14.（限制认识了新朋友回答）你跟游戏中认识的朋友线下见过面吗？", options: [{ content: "见过面", tot: 29 }, { content: "没见过", tot: 154 }] },
    { value: "15.你觉得你在平时的生活中存在拖延的现象吗？", options: [{ content: "存在", tot: 148 }, { content: "不存在，我超利落的", tot: 57 }] },
    { value: "16.打游戏时情绪你是不是会变得暴躁呢？", options: [{ content: "确实会", tot: 63 }, { content: "心态好，神马都是浮云", tot: 142 }] },
    { value: "17.电竞将于2022年杭州亚运会首次作为比赛项目，你支持吗？", options: [{ content: "电竞属于电子体育竞技，兹磁！", tot: 108 }, { content: "玩物丧志，不支持", tot: 97 }] }
 ];
  return (
    <div className="App">
      <Row>
        <Col xs={2} sm={3} md={4} lg={5} xl={6} xxl={7}>
        </Col>
        <Col style={{ backgroundColor: '' }} xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
          <div className="title" style={{ marginTop: 20 }}>
            <h1 style={{ fontWeight: 'bold' }}>调查问卷统计结果</h1>
            <span>本报告可通过<a href="http://mg.xjzsq.ren">报告生成系统</a>在线查看</span>
          </div>
          <div>
            {data.map((data) =>
              <Card title={data.value} style={{ marginTop: 20 }}>
                {/* extra = {< a href="#">饼状图</a>} */}
                {data.options.map(
                  (choice) =>
                    <Row className="ans">
                      <Col span="8" >{choice.content}</Col>
                      <Col span="2">{choice.tot}</Col>
                      <Col span="14">
                        <Progress percent={(choice.tot * 100 / (() => {
                          let ans = 0;
                          data.options.map((e) => { ans += e.tot });
                          return ans;
                        })()).toFixed(2)} status="active" />
                      </Col>
                    </Row>

                )}
              </Card>
            )}
          </div>
        </Col>
        <Col xs={2} sm={3} md={4} lg={5} xl={6} xxl={7}>
        </Col>
      </Row>
    </div>
  );
}

export default App;
